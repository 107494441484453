import { Fragment, useState } from "react";
import Select from "react-select";
import { post } from "../../../../lib/helperFunctions";

const AddOTC = (props) => {
  const [customerID, setCustomerID] = useState("");
  const [cost, setCost] = useState(0);
  const [currency, setCurrency] = useState("CAD"); //Default to CAD
  const [tax, setTax] = useState([]);
  const [description, setDescription] = useState("");

  const supportedCurrencies = [
    { value: "CAD", label: "CAD" },
    { value: "USD", label: "USD" },
  ]; //CAD must be first as is default
  const supportedTaxes = [{ value: "canGST", label: "GST" }];
  const submit = (e) => {
    console.log({ e });
    e.preventDefault();
    e.target.reset();
    const payload = {
      customerID,
      cost: parseInt(cost),
      currency,
      tax,
      description,
    };
    const request = async (payload) => {
      props.setError(); //Default to empty on submit
      props.setResp(); //Default to empty on submit
      console.log({ payload });
      const resp = await post("addOTC", props.user, payload);
      console.log({ resp });
      if (resp.error) {
        props.setError(resp);
      } else {
        props.setResp(resp);
      }
    };
    request(payload);
  };
  const processTax = (e) => {
    //Strips object and creates an array of tax values.
    const taxes = [];
    if (e.length > 0) {
      for (const t of e) {
        taxes.push(t.value);
      }
    }
    setTax(taxes); //Sets taxes with extracted value.
  };
  return (
    <Fragment>
      <h2>Add OTC</h2>
      <form onSubmit={submit}>
        <div>
          <label>Customer ID</label>
          <input
            type="text"
            className="form-control"
            placeholder="guid"
            onChange={(e) => setCustomerID(e.target.value)}
          ></input>
        </div>
        <div>
          <label>Cost</label>
          <input
            type="number"
            className="form-control"
            placeholder="0"
            onChange={(e) => setCost(e.target.value)}
          ></input>
        </div>
        <div>
          <label>Currency</label>
          <Select
            className="basic-single"
            classNamePrefix="select"
            defaultValue={supportedCurrencies[0]}
            name="currency"
            options={supportedCurrencies}
            onChange={(e) => {
              setCurrency(e.value);
            }}
          />
        </div>
        <div>
          <label>Tax</label>
          <Select
            isMulti
            name="tax"
            options={supportedTaxes}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(e) => {
              processTax(e);
            }}
          />
          <div>
            <label>Description</label>
            <input
              type="text"
              className="form-control"
              placeholder="charge for..."
              onChange={(e) => setDescription(e.target.value)}
            ></input>
          </div>
        </div>
        <button type="submit" className="submitButton marginTop">
          Submit
        </button>
      </form>
    </Fragment>
  );
};
export default AddOTC;
