import React, { Component } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import AdminHome from "./status/adminHome";
import AdminBilling from "./billing/adminBilling";
import AdminSprout from "./sprout/adminSprout";
import AdminTelco from "./telco/adminTelco";
import AdminWALSYS from "./wal-sys/adminWAL-SYS";
import LobNav from "./lobNav";

export default class Admin extends Component {
  user = this.props.user;
  state = { path: "/admin" };

  updatePath = () => {
    console.log("update");
    let path = window.location.pathname;
    if (this.state.path !== path) {
      this.setState({ path });
    }
  };
  componentDidUpdate() {
    this.updatePath();
  }
  render() {
    return (
      <div className="innerContainerAutoSize">
        <BrowserRouter>
          <LobNav path={this.state.path} user={this.user} />
          <Switch>
            <Route exact path="/admin">
              <Redirect to="/admin/status" />
            </Route>
            <Route exact path="/admin/status">
              <AdminHome user={this.user} updatePath={this.updatePath} />
            </Route>
            <Route exact path="/admin/wal-sys">
              <AdminWALSYS user={this.user} updatePath={this.updatePath} />
            </Route>
            <Route exact path="/admin/billing">
              <AdminBilling user={this.user} updatePath={this.updatePath} />
            </Route>
            <Route exact path="/admin/sprout">
              <AdminSprout user={this.user} updatePath={this.updatePath} />
            </Route>
            <Route exact path="/admin/telco">
              <AdminTelco user={this.user} updatePath={this.updatePath} />
            </Route>
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}
