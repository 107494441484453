import { useEffect, Fragment, useState } from "react";
import { Link } from "react-router-dom";

const LobNav = (props) => {
  const [path, setPath] = useState(0);

  useEffect(async () => {
    if (path !== props.path) {
      setPath(props.path);
    }
  });
  const groupHide = (lob) => {
    if (!props?.user?.groups) {
      return true;
    } //Hide during loading
    if (props.user.groups.includes("admin_walsys")) {
      return false;
    }
    if (props.user.groups.includes("admin_" + lob)) {
      return false;
    }
    return true;
  };
  const boldNavClass = (nav) => {
    console.log({ path, nav });
    if (path !== nav) {
      return "subNav-item-lite";
    } else {
      return "subNav-item-bold";
    }
  };
  return (
    <Fragment>
      <ul id="lobNav" className="subNav">
        <Link to={"/admin/status"} className={boldNavClass("/admin/status")}>
          Status
        </Link>
        <Link
          to={"/admin/wal-sys"}
          className={boldNavClass("/admin/wal-sys")}
          hidden={groupHide()}
        >
          WAL-SYS
        </Link>
        <Link
          to={"/admin/billing"}
          className={boldNavClass("/admin/billing")}
          hidden={groupHide("billing")}
        >
          Billing
        </Link>
        <Link
          to={"/admin/telco"}
          className={boldNavClass("/admin/telco")}
          hidden={groupHide("telco")}
        >
          Telco
        </Link>
        <Link
          to={"/admin/sprout"}
          className={boldNavClass("/admin/sprout")}
          hidden={groupHide("Sprout")}
        >
          Sprout
        </Link>
      </ul>
    </Fragment>
  );
};
export default LobNav;
