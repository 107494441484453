import { useEffect, Fragment, useState } from "react";
import { get } from "../../../lib/helperFunctions";
import Resp from "../../common/jsonResp";
const AdminStatus = (props) => {
  const [admin, setAdmin] = useState({});

  useEffect(async () => {
    console.log({ admin });
    if (JSON.stringify(admin) === "{}") {
      const adminStatus = await get("adminStatus", props.user);
      console.log({ adminStatus });
      setAdmin(adminStatus);
    }
  });
  return (
    <Fragment>
      <Resp resp={admin} name={"Response"} />
    </Fragment>
  );
};
export default AdminStatus;
