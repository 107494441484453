import { Fragment, useState, useEffect } from "react";
import { get } from "../../../../lib/helperFunctions";
import JsonTable from "../../../common/jsonTable";

const InvoiceList = (props) => {
  const [invoices, setInvoices] = useState({});

  useEffect(async () => {
    if (JSON.stringify(invoices) === "{}") {
      const resp = await get("getInvoices", props.user);
      if (resp.error) {
        props.setError(resp), setInvoices(invoices);
      } else {
        console.log({ resp });
        setInvoices(resp.invoices);
      }
    }
  });

  if (invoices.error !== true) {
    return (
      <Fragment>
        <h2>Invoices</h2>
        <JsonTable data={invoices} />
      </Fragment>
    );
  } else {
    <Fragment>
      <h2>Invoices</h2>
    </Fragment>;
  }
};
export default InvoiceList;
