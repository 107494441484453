import { useEffect, Fragment, useState } from "react";
import Resp from "../../common/jsonResp";
import Error from "../../common/jsonError";
import AddOTC from "./billingComponents/addOTC";
import QueueInvoice from "./billingComponents/queueInvoice";
import AddPayment from "./billingComponents/addPayment";
import CustomerList from "./billingComponents/customerList";
import InvoiceList from "./billingComponents/invoiceList";
import Select from "react-select";

const AdminBilling = (props) => {
  const [component, setComponent] = useState("");
  const [error, setError] = useState(); //Error state
  const [resp, setResp] = useState(); //Error state

  props.updatePath();
  const components = [
    { value: "AddOTC", label: "AddOTC" },
    { value: "QueueInvoice", label: "Queue Invoice Run" },
    { value: "AddPayment", label: "Add Payment" },
    { value: "CustomerList", label: "Customer List" },
    { value: "InvoiceList", label: "Invoice List" },
  ]; //Define all components for select
  const resetData = () => {
    if (typeof error !== "undefined") {
      setError();
    }
    if (typeof resp !== "undefined") {
      setResp();
    }
  };
  const returnComponent = () => {
    //Define all components that will be returned (should match above)
    switch (component) {
      case "AddOTC":
        return (
          <Fragment>
            <AddOTC setError={setError} setResp={setResp} user={props.user} />
          </Fragment>
        );
      case "QueueInvoice":
        return (
          <Fragment>
            <QueueInvoice
              setError={setError}
              setResp={setResp}
              user={props.user}
            />
          </Fragment>
        );
      case "AddPayment":
        return (
          <Fragment>
            <AddPayment
              setError={setError}
              setResp={setResp}
              user={props.user}
            />
          </Fragment>
        );
      case "CustomerList":
        return (
          <Fragment>
            <CustomerList user={props.user} setError={setError} />
          </Fragment>
        );
      case "InvoiceList":
        return (
          <Fragment>
            <InvoiceList user={props.user} setError={setError} />
          </Fragment>
        );
      default:
        return <Fragment />;
    }
  };
  return (
    <Fragment>
      <div className="quadCard">
        <div className="tl">
          <h2>Billing</h2>
        </div>
        <div className="tr">
          <Select
            className="basic-single"
            classNamePrefix="select"
            name="component"
            options={components}
            onChange={(e) => {
              resetData(), setComponent(e.value);
            }}
          />
        </div>
      </div>
      <Resp resp={resp} name={"Response"} />
      <Error resp={error} name="Error" />
      {returnComponent()}
    </Fragment>
  );
};
export default AdminBilling;
