import { Fragment } from "react";
const AdminSprout = (props) => {
  props.updatePath();

  return (
    <Fragment>
      <h2>Admin Sprout</h2>
    </Fragment>
  );
};
export default AdminSprout;
