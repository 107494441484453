import { Fragment, useState, useEffect } from "react";
import { get } from "../../../../lib/helperFunctions";
import JsonTable from "../../../common/jsonTable";

const CustomerList = (props) => {
  const [customers, setCustomers] = useState({});

  useEffect(async () => {
    if (JSON.stringify(customers) === "{}") {
      const resp = await get("getCustomers", props.user);
      if (resp.error) {
        props.setError(resp), setCustomers(customers);
      } else {
        console.log({ resp });
        setCustomers(resp.customers);
      }
    }
  });

  if (customers.error !== true) {
    return (
      <Fragment>
        <h2>Customers</h2>
        <JsonTable data={customers} />
      </Fragment>
    );
  } else {
    <Fragment>
      <h2>Customers</h2>
    </Fragment>;
  }
};
export default CustomerList;
