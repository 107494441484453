import React, { Component } from "react";
import { Redirect } from "react-router-dom";

export default class Home extends Component {
  render() {
    if (this.props.user) {
      return <Redirect to="/admin" />;
    }
    return (
      <div className="innerContainerAutoSize">
        <h2 id="home-headerNotLoggedIn">You are not logged in.</h2>
      </div>
    );
  }
}
