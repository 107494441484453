import { Fragment } from "react";
const AdminWALSYS = (props) => {
  props.updatePath();

  return (
    <Fragment>
      <h2>Admin WALSYS</h2>
    </Fragment>
  );
};
export default AdminWALSYS;
