import ReactJson from "react-json-view";
import { Fragment, useState } from "react";

const JsonError = (props) => {
  const [resp, setResp] = useState({});
  const [name, setName] = useState("Error");
  if (resp !== props.resp) {
    setResp(props.resp);
  } //Always use props resp in local state.
  if (name !== props.name) {
    setName(props.name);
  } // |
  if (
    typeof resp === "object" &&
    JSON.stringify(resp) !== "{}" &&
    typeof name === "string"
  ) {
    //Only return if resp is object and has data and name is valid.
    return (
      <Fragment>
        <ReactJson name={name} displayDataTypes={false} src={resp} />
      </Fragment>
    );
  } else {
    //Return empty response
    return <Fragment></Fragment>;
  }
};

export default JsonError;
