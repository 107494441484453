import { Fragment, useState } from "react";
import Select from "react-select";
import { post } from "../../../../lib/helperFunctions";

const QueueInvoice = (props) => {
  const [customerID, setCustomerID] = useState("");
  const [billRun, setBillRun] = useState("");
  const supportedBillRun = [
    { value: "monthly", label: "Monthly" },
    { value: "manual", label: "Manual" },
  ];
  const submit = (e) => {
    console.log({ e });
    e.preventDefault();
    e.target.reset();
    const payload = {
      customerID,
      billRun,
    };
    const request = async (payload) => {
      props.setError(); //Default to empty on submit
      props.setResp(); //Default to empty on submit
      console.log({ payload });
      const resp = await post("billRun", props.user, payload);
      console.log({ resp });
      if (resp.error) {
        props.setError(resp);
      } else {
        props.setResp(resp);
      }
    };
    request(payload);
  };
  return (
    <Fragment>
      <h2>Queue Invoice Run</h2>
      <form onSubmit={submit}>
        <div>
          <label>Customer ID</label>
          <input
            type="text"
            className="form-control"
            placeholder="guid"
            onChange={(e) => setCustomerID(e.target.value)}
          ></input>
        </div>
        <div>
          <label>Bill Run Type</label>
          <Select
            className="basic-single"
            classNamePrefix="select"
            name="billRunType"
            options={supportedBillRun}
            onChange={(e) => {
              setBillRun(e.value);
            }}
          />
        </div>
        <button type="submit" className="submitButton marginTop">
          Submit
        </button>
      </form>
    </Fragment>
  );
};
export default QueueInvoice;
