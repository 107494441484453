import { JsonToTable } from "react-json-to-table";
import { Fragment, useState } from "react";
import ScrollContainer from "react-indiana-drag-scroll";

//Requires react-json-table

const JsonResp = (props) => {
  const [data, setData] = useState({});
  if (data !== props.data) {
    setData(props.data);
  } //Always use props resp in local state./ |
  if (typeof data === "object" && JSON.stringify(data) !== "{}") {
    //Only return if resp is object and has data and name is valid.
    console.log({ data });
    return (
      <Fragment>
        <ScrollContainer
          className="scroll-container table"
          hideScrollbars={false}
        >
          <JsonToTable json={data} />
        </ScrollContainer>
      </Fragment>
    );
  } else {
    //Return empty response
    console.log("invalid data");
    return <Fragment></Fragment>;
  }
};

export default JsonResp;
