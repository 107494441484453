import React, { Component } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import NavBar from "./components/navbar";
import Home from "./components/home";
import Admin from "./components/admin/admin";
import SSOLoginReferrer from "./components/common/ssoLoginReferrer";
import SSORegister from "./components/common/ssoRegister";
import SSOLoginHandler from "./components/common/ssoLoginHandler";
import Footer from "./components/common/footer";
import NotFound from "./components/common/404";
import SSOLogout from "./components/common/ssoLogout";

import tokenManager from "./lib/token";
import "./WALSYS.css";

export default class App extends Component {
  state = {};

  setUser = (user) => {
    this.setState({ user });
  };
  sessionToken = new tokenManager(this.setUser);
  setLoginRequest = (loginReq) => {
    this.setState({ loginReq });
  };

  setLoginRequest = (loginReq) => {
    this.setState({ loginReq });
  };
  isLoggedIn = () => {
    return this.sessionToken.isLoggedIn();
  };
  getToken = () => {
    return this.sessionToken.getToken();
  };
  setToken = async (token) => {
    this.sessionToken.setToken(token);
  };
  initSSOLogout = async () => {
    await this.sessionToken.initSSOLogout();
  };
  ssoLogout = async () => {
    await this.sessionToken.ssoLogout();
  };
  async componentDidMount() {
    this.sessionToken.init();
  }

  render() {
    return (
      <BrowserRouter>
        <NavBar user={this.state.user} initSSOLogout={this.initSSOLogout} />
        <main>
          <Switch>
            <Route
              exact
              path="/"
              component={() => <Home user={this.state.user} />}
            />
            <Route
              path="/admin"
              component={() => <Admin user={this.state.user} />}
            />
            <Route
              exact
              path="/login"
              component={() => <Redirect to="/ssoLoginReferrer" />}
            />
            <Route
              exact
              path="/register"
              component={() => <Redirect to="/ssoRegister" />}
            />
            <Route
              exact
              path="/ssoLoginReferrer"
              component={() => (
                <SSOLoginReferrer versionInfo={this.state.versionInfo} />
              )}
            />
            <Route
              exact
              path="/ssoRegister"
              component={() => (
                <SSORegister versionInfo={this.state.versionInfo} />
              )}
            />
            <Route
              exact
              path="/ssoLoginHandler/:ssoResponse"
              component={(props) => (
                <SSOLoginHandler
                  setToken={this.setToken}
                  user={this.state.user}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/ssoLogoutHandler"
              component={() => <SSOLogout ssoLogout={this.ssoLogout} />}
            />
            <Route path="/" component={() => <NotFound />} />
          </Switch>
        </main>
        <div className="bottomBuffer" />
        <Footer user={this.state.user} />
      </BrowserRouter>
    );
  }
}
