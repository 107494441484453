import { useEffect, Fragment, useState } from "react";
import Resp from "../../common/jsonResp";
import Error from "../../common/jsonError";
import CancelTN from "./telcoComponents/CancelTN";
import Select from "react-select";

const AdminTelco = (props) => {
  const [component, setComponent] = useState("");
  const [error, setError] = useState(); //Error state
  const [resp, setResp] = useState(); //Error state

  props.updatePath();
  const components = [{ value: "CancelTN", label: "CancelTN" }]; //Define all components for select
  const resetData = () => {
    if (typeof error !== "undefined") {
      setError();
    }
    if (typeof resp !== "undefined") {
      setResp();
    }
  };
  const returnComponent = () => {
    //Define all components that will be returned (should match above)
    switch (component) {
      case "CancelTN":
        return (
          <Fragment>
            <Resp resp={resp} name={"Response"} />
            <Error resp={error} name="Error" />
            <CancelTN setError={setError} setResp={setResp} user={props.user} />
          </Fragment>
        );
      default:
        return <Fragment />;
    }
  };
  return (
    <Fragment>
      <div className="quadCard">
        <div className="tl">
          <h2>Telco</h2>
        </div>
        <div className="tr">
          <Select
            className="basic-single"
            classNamePrefix="select"
            name="component"
            options={components}
            onChange={(e) => {
              resetData(), setComponent(e.value);
            }}
          />
        </div>
      </div>

      {returnComponent()}
    </Fragment>
  );
};
export default AdminTelco;
