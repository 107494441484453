import { Fragment } from "react";
import AdminStatus from "./adminStatus";
const AdminHome = (props) => {
  props.updatePath();
  return (
    <Fragment>
      <h2>Admin Status</h2>
      <AdminStatus user={props.user} />
    </Fragment>
  );
};
export default AdminHome;
