import { Fragment, useState } from "react";
import Select from "react-select";
import { post } from "../../../../lib/helperFunctions";

const AddPayment = (props) => {
  const [invoiceID, setInvoiceID] = useState("");
  const [payment, setPayment] = useState(0);
  const [memo, setMemo] = useState("");

  const submit = (e) => {
    console.log({ e });
    e.preventDefault();
    e.target.reset();
    const payload = {
      invoiceID,
      payment: parseInt(payment),
      memo,
    };
    const request = async (payload) => {
      props.setError(); //Default to empty on submit
      props.setResp(); //Default to empty on submit
      console.log({ payload });
      const resp = await post("addPayment", props.user, payload);
      console.log({ resp });
      if (resp.error) {
        props.setError(resp);
      } else {
        props.setResp(resp);
      }
    };
    request(payload);
  };
  return (
    <Fragment>
      <h2>Add Payment</h2>
      <form onSubmit={submit}>
        <div>
          <label>Invoice ID</label>
          <input
            type="text"
            className="form-control"
            placeholder="guid"
            onChange={(e) => setInvoiceID(e.target.value)}
          ></input>
        </div>
        <div>
          <label>Payment Amount (Cents)</label>
          <input
            type="number"
            className="form-control"
            placeholder="0"
            onChange={(e) => setPayment(e.target.value)}
          ></input>
        </div>
        <div>
          <label>Memo</label>
          <input
            type="text"
            className="form-control"
            placeholder=""
            name="memo"
            onChange={(e) => {
              setMemo(e.value);
            }}
          ></input>
        </div>
        <button type="submit" className="submitButton marginTop">
          Submit
        </button>
      </form>
    </Fragment>
  );
};
export default AddPayment;
