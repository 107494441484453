import { Fragment, useState } from "react";
import { post } from "../../../../lib/helperFunctions";

const CancelTN = (props) => {
  const [tn, setTN] = useState("");
  const [deleteTN, setDeleteTN] = useState(false); //Default to false
  const [force, setForce] = useState(false); //Default to false

  const submit = (e) => {
    console.log({ e });
    e.preventDefault();
    e.target.reset();
    const payload = {
      tn,
      deleteTN,
      force,
    };
    const request = async (payload) => {
      props.setError(); //Default to empty on submit
      props.setResp(); //Default to empty on submit
      console.log({ payload });
      const resp = await post("cancelTN", props.user, payload);
      console.log({ resp });
      if (resp.error) {
        props.setError(resp);
      } else {
        props.setResp(resp);
      }
    };
    request(payload);
  };
  const toggleDelTN = () => {
    if (deleteTN === true) {
      setDeleteTN(false);
    } else {
      setDeleteTN(true);
    }
  };
  const toggleForce = () => {
    if (force === true) {
      setForce(false);
    } else {
      setForce(true);
    }
  };
  return (
    <Fragment>
      <h2>Cancel TN</h2>
      <form onSubmit={submit}>
        <div>
          <label>Telephone Number</label>
          <input
            type="text"
            className="form-control"
            placeholder="+17785550123"
            onChange={(e) => setTN(e.target.value)}
          ></input>
        </div>
        <div className="keyValue">
          <div className="key">
            <label className="marginTop">Delete Number Via TELNYX</label>
          </div>
          <div className="value">
            <input
              name="deleteTN"
              type="checkbox"
              checked={deleteTN}
              onChange={toggleDelTN}
            />
          </div>
        </div>
        <div className="keyValue">
          <div className="key">
            <label className="marginTop">Force</label>
          </div>
          <div className="value">
            <input
              name="force"
              type="checkbox"
              checked={force}
              onChange={toggleForce}
            />
          </div>
        </div>
        <button type="submit" className="submitButton marginTop">
          Submit
        </button>
      </form>
    </Fragment>
  );
};
export default CancelTN;
